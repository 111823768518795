import { Directive, ElementRef, inject, input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { RouterLink, UrlTree } from '@angular/router';
import { LinkHandler } from './link-handler.service';
import { LinkHandlerStrategy } from './link-handler-strategy';

@Directive({
  selector: '[routerLink]',
  standalone: true,
})
export class LinkDirective implements OnChanges, OnDestroy {
  routerLink = input<Pick<RouterLink, 'routerLink'>['routerLink']>();
  private readonly linkHandlers: LinkHandlerStrategy[] = inject(LinkHandler);
  private readonly el: ElementRef = inject(ElementRef);
  private readonly link: RouterLink | null = inject(RouterLink, { optional: true });
  private readonly linkWithHref: RouterLink | null = inject(RouterLink, { optional: true });
  private rl: RouterLink | null;
  private readonly linkHandler: LinkHandlerStrategy | undefined;

  constructor() {
    this.linkHandler = this.linkHandlers.filter(h => h.supported()).shift();
    this.rl = this.link || this.linkWithHref;
    if (this.element && this.element.setAttribute) {
      this.element.setAttribute('ngx-ql', '');
    }
  }

  get element(): Element {
    return this.el.nativeElement;
  }

  get urlTree(): UrlTree | null {
    if (this.rl) {
      return this.rl.urlTree;
    }

    return null;
  }

  ngOnChanges(c: SimpleChanges) {
    if (c['routerLink'] && this.linkHandler) {
      this.linkHandler.unregister(this);
      this.linkHandler.register(this);
    }
  }

  ngOnDestroy() {
    if (!this.linkHandler) {
      return;
    }
    this.linkHandler.unregister(this);
  }
}
