import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { LocalizeRouterService } from './localize-router.service';
import { Subscription } from 'rxjs';
import { equals } from './util';

const VIEW_DESTROYED_STATE = 128;

@Pipe({
  name: 'localize',
  pure: false, // required to update the value when the promise is resolved
  standalone: true,
})
export class LocalizeRouterPipe implements PipeTransform, OnDestroy {
  private localize = inject(LocalizeRouterService);
  private _ref = inject(ChangeDetectorRef);
  private value: string | any[] = '';
  private lastKey: string | any[] | undefined;
  private lastLanguage: string | undefined;
  private subscription: Subscription;

  /**
   * CTOR
   */
  constructor() {
    this.subscription = this.localize.routerEvents.subscribe(() => {
      this.transform(this.lastKey);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Transform current url to localized one
   */
  transform(query: string | any[] | undefined): string | any[] | undefined {
    if (!query || query.length === 0 || !this.localize.parser.currentLang) {
      return query;
    }
    if (equals(query, this.lastKey) && equals(this.lastLanguage, this.localize.parser.currentLang)) {
      return this.value;
    }
    this.lastKey = query;
    this.lastLanguage = this.localize.parser.currentLang;

    /** translate key and update values */
    this.value = this.localize.translateRoute(query);
    this.lastKey = query;
    // if view is already destroyed, ignore firing change detection
    const view = (this._ref as any)._view;
    if (view && view.state & VIEW_DESTROYED_STATE) {
      return this.value;
    }
    setTimeout(() => {
      this._ref.detectChanges();
    }, 0);

    return this.value;
  }
}
